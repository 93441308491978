<template>
  <div>
    <h1>Admin Users</h1>
    <p>Manage users here.</p>
  </div>
</template>

<script>
export default {
  name: 'AdminUsers',
};
</script>