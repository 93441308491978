<template>
  <div>
    <h1>Admin Settings</h1>
    <p>Manage settings here.</p>
  </div>
</template>

<script>
export default {
  name: 'AdminSettings',
};
</script>