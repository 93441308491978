<template>
  <v-container class="d-flex justify-center align-center" style="height: 100vh;">
    <v-card class="pa-6" max-width="600px" width="500px">
      <!-- Logo Section -->
      <v-card-title class="justify-center">
      </v-card-title>

      <!-- Title Section -->
      <v-card-title class="text-h5 justify-center mt-4">Register Admin</v-card-title>

      <!-- Registration Form -->
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="handleRegister">
        <v-text-field
            v-model="username"
            label="Username"
            type="text"
            required
            :rules="[rules.requiredl]"
          ></v-text-field>
          <v-text-field
            v-model="email"
            label="Email"
            type="email"
            required
            :rules="[rules.required, rules.email]"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            required
            :rules="[rules.required, rules.password]"
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            label="Confirm Password"
            type="password"
            required
            :rules="[rules.required, confirmPasswordRule]"
          ></v-text-field>
          <v-btn type="submit" block color="primary" :disabled="!valid">Register</v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="$router.push('/admin/login')">
          Have an account? Login here
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminRegister",
  data() {
    return {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      valid: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        email: (value) => /.+@.+\..+/.test(value) || "Invalid email.",
        password: (value) =>
          value.length >= 6 || "Password must be at least 6 characters.",
      },
    };
  },
  computed: {
    confirmPasswordRule() {
      return (value) =>
        value === this.password || "Passwords do not match.";
    },
  },
  methods: {
    handleRegister() {
      if (!this.valid) {
        alert("Please fix the errors before submitting.");
        return;
      }

      const newUser = {
        email: this.email,
        username: this.username, 
        password: this.password,
      };

      const apiUrl = process.env.VUE_APP_API_URL;
     
      axios
        .post(`${apiUrl}/admin/register`, newUser) 
        .then((response) => {
          console.log(response.data);
          alert("Registration successful. You can now log in.");
          this.$router.push("/admin/login"); 
        })
        .catch((error) => {
          console.error("Registration failed", error);

          if (error.response && error.response.data) {
            alert(error.response.data);
          } else {
            alert("Error occurred during registration.");
          }
        });
    },
  },
};
</script>

<style scoped>
.v-container {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>