import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import vuetify from './plugins/vuetify';
import './styles/main.css';
import 'vuetify/styles'; // Vuetify styles
import '@mdi/font/css/materialdesignicons.css'; // Material Design Icons

createApp(App)
  .use(router)
  .use(vuetify)
  .mount('#app');

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  
  if (token) {
    localStorage.setItem('userAuthenticated', token); // Save token
    window.history.replaceState({}, document.title, '/'); // Clean URL
  }
  