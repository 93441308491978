<template>
  <v-app-bar app>
     <v-app-bar-nav-icon @click="drawer = !drawer" v-if="isSmAndDown" />
    <v-toolbar-title>Admin Panel</v-toolbar-title>
    <v-spacer />

    <v-menu offset-y v-model="logoutMenu" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text>
          <v-icon>mdi-account-circle</v-icon>
          <span class="ml-2 username-box" v-bind="attrs" v-on="on">
            {{ userName }}
          </span>
          <v-btn 
            icon 
            @click.stop="logout" 
            class="ml-2"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-btn>
      </template>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { useDisplay } from "vuetify";

export default {
  name: "AdminToolBar",
   data() {
    return {
      userName: "Admin", 
      logoutMenu: false,
    };
  },
  emits: ["toggle-drawer"],
  setup(_, { emit }) {
    const { smAndDown } = useDisplay();

    const toggleDrawer = () => {
      emit("toggle-drawer");
    };

    return {
      isSmAndDown: smAndDown,
      toggleDrawer,
    };
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.name) {
      this.userName = user.name;
    }
  },
  methods: {
    logout() {
        localStorage.removeItem("isAuthenticated");
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
        localStorage.removeItem("username");
        this.$router.push('/admin/login');
    },
  },
};
</script>

<style scoped>
.v-app-bar {
  background-color: #1976d2;
  color: white;
}
.ml-2 {
  margin-left: 8px;
}
.username-box {
  cursor: pointer;
}
</style>