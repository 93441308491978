<template>
  <v-container class="d-flex justify-center align-center" style="height: 100vh;">
    <v-card class="pa-6" max-width="600px" width="500px">
      <!-- Logo Section -->
      <v-card-title class="justify-center">
        <!-- Add your logo here if needed -->
      </v-card-title>

      <!-- Title Section -->
      <v-card-title class="text-h5 text-center mb-4">Admin Login</v-card-title>

      <!-- Login Form -->
      <v-card-text>
         <!--<v-form v-model="valid" @submit.prevent="handleLogin">
          <v-text-field
            v-model="username"
            label="Username"
            type="username"
            required
            :rules="[rules.required, rules.email]"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            required
            :rules="[rules.required]"
          ></v-text-field>
          <v-btn type="submit" block color="primary" :disabled="!valid">Login</v-btn>
        </v-form>-->
        <v-btn block color="secondary" @click="googleLogin">Login with Google</v-btn>
      </v-card-text>

      <!-- Register Link -->
      <!-- <v-card-actions>
        <v-btn text @click="$router.push('/admin/register')">
          Don't have an account? Register here
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '', // Changed from email to username
      password: '',
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        username: (value) =>
          /^[a-zA-Z0-9_-]{3,16}$/.test(value) || 'Invalid username. (3-16 characters, no special symbols)',
      },
    };
  },
  methods: {
    handleLogin() {
      const credentials = {
        username: this.username, 
        password: this.password,
      };

       const apiUrl = process.env.VUE_APP_API_URL;

      axios
        .post(`${apiUrl}/admin/login`, credentials) 
        .then((response) => {
          console.log(response.data);
          localStorage.setItem('isAuthenticated', response.data.token);
          this.$router.push('/admin'); 
        })
        .catch((error) => {
          console.error('Login failed', error);
          alert('Invalid credentials');
        });
    },
    googleLogin() {
      const apiUrl = process.env.VUE_APP_API_URL;
      window.location.href = `${apiUrl}/auth/google/admin`;
    }
  },
};
</script>

<style scoped>
.v-container {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>