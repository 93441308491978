import { createRouter, createWebHistory } from 'vue-router';
import AdminLayout from '@/layouts/AdminLayout.vue';
import WalletLayout from '@/layouts/WalletLayout.vue';
import AdminDashboard from '@/views/admin/AdminDashboard.vue';
import AdminUsers from '@/views/admin/AdminUsers.vue';
import AdminSettings from '@/views/admin/AdminSettings.vue';
import AdminLogin from "@/views/admin/AdminLogin.vue";
import AdminRegister from "@/views/admin/AdminRegister.vue";
import UserLogin from "@/views/UserLogin.vue";
import UserRegister from "@/views/UserRegister.vue";
import { jwtDecode } from "jwt-decode";

const routes = [
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
    beforeEnter: (to, from, next) => {
      const authToken = localStorage.getItem("isAuthenticated"); 
      if (authToken) {
        next("/admin"); 
      } else {
        next();
      }
    },
  },
  {
    path: "/admin/register",
    name: "AdminRegister",
    component: AdminRegister,
  },
  {
    path: "/admin",
    component: AdminLayout,
    beforeEnter: (to, from, next) => {
      const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('token='))
      ?.split('=')[1];
      if(token){
        localStorage.setItem('isAuthenticated', token);
      }
      const authToken = localStorage.getItem("isAuthenticated"); 
      if(authToken){
        const decoded = jwtDecode(authToken);
        var expired=false;
        const isExpired = decoded.exp * 1000 < Date.now();
        if(isExpired){
          localStorage.removeItem("isAuthenticated");
          expired = true; 
        }
      }
      
      if (authToken && !expired) {
        next(); 
      } else {
        next("/admin/login"); 
      }
    },
    children: [
      { path: "", name: "AdminDashboard", component: AdminDashboard },
      { path: "users", name: "AdminUsers", component: AdminUsers },
      { path: "settings", name: "AdminSettings", component: AdminSettings },
    ],
  },
  {
    path: "/",
    component: WalletLayout,
    beforeEnter: (to, from, next) => {
      const token = document.cookie
        .split('; ')
        .find(row => row.startsWith('token='))
        ?.split('=')[1];
      if(token){
        localStorage.setItem('userAuthenticated', token);
      }
      const authToken = localStorage.getItem("userAuthenticated"); 
      if(authToken){
        const decoded = jwtDecode(authToken);
        var expired=false;
        const isExpired = decoded.exp * 1000 < Date.now();
        if(isExpired){
          localStorage.removeItem("userAuthenticated");
          expired = true; 
        }
      }
      
      if (authToken && !expired) {
        next(); 
      } else {
        next("/login");
      }
    },
    children: [],
  },
  {
    path: "/login",
    name: "UserLogin",
    component: UserLogin,
    beforeEnter: (to, from, next) => {
      const userAuthenticated = localStorage.getItem("userAuthenticated"); 
      if (userAuthenticated) {
        next("/"); 
      } else {
        next();
      }
    },
  },
  {
    path: "/register",
    name: "UserRegister",
    component: UserRegister, 
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;