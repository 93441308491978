<template>
  <v-app>
    <WalletToolbar @transaction-sent="fetchUserBalance" ref="walletToolbar" />
    <!--<WalletSidebar />-->
    <v-main>
      <v-container>
        <!-- Send Form -->
        <v-form @submit.prevent="handleSend" ref="sendForm">
          <v-text-field v-model="recipientUsername" label="Recipient Username" required />
          <v-btn @click="startScanning" class="mb-2">Scan QR</v-btn>
          <v-btn v-if="scanning" @click="cancelScanning" color="red">Cancel</v-btn>
          <qrcode-stream v-if="scanning" @decode="onDecode" @error="onError" />
          <v-text-field v-model="amount" label="Amount" type="number" required />
          <v-btn type="submit" color="primary">Send</v-btn> <v-btn color="primary" class="ml-2" @click="showReceivePopup = true">Receive</v-btn>
        </v-form>
      </v-container>

      <v-container>
        

        <!-- Receive QR Popup -->
        <v-dialog v-model="showReceivePopup" max-width="400px">
          <v-card>
            <v-card-title class="text-center">Receive Payment</v-card-title>
            <v-card-text>
              <div class="text-center">
                <img v-if="qrCodeData" :src="qrCodeData" alt="QR Code" />
                <p v-if="username">Username<br>{{ username }}</p>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="red" text @click="showReceivePopup = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

      <v-container>
        <h3>Latest Transactions</h3>
        <v-card outlined>
          <v-card-text>
          <div class="transaction-table-wrapper">
            <v-simple-table dense class="transaction-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Recipient Username</th>
                    <th>Transaction Type</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in transactions" :key="transaction.id">
                    <td>{{ transaction.date }}</td>
                    <td>{{ transaction.recipientUsername }}</td>
                    <td>{{ transaction.transactionType }}</td>
                    <td>{{ transaction.amount }}</td>
                    <td>
                      <v-chip :color="getStatusColor(transaction.status)" dark>
                        {{ transaction.status }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn 
                        v-if="transaction.status === 'pending'" 
                        color="red" 
                        @click="cancelTransaction(transaction.id)"
                      >
                        Cancel
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-container>

      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import WalletToolbar from '../components/WalletToolbar.vue';
import QRCode from 'qrcode';
import axios from 'axios';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'WalletLayout',
  components: {
    WalletToolbar,
    QrcodeStream
  },
  data() {
    return {
      recipientUsername: '',
      amount: 0,
      transactions: [],
      showReceivePopup: false,
      qrCodeData: '',
      username: '',
      scanning: false,
    };
  },
  methods: {
    handleSend() {
      const userAuthToken = localStorage.getItem('userAuthenticated');
      if (!userAuthToken) {
        alert('No token found. Please log in again.');
        this.$router.push('/login');
        return;
      }

      const payload = {
        recipientUsername: this.recipientUsername,
        amount: parseFloat(this.amount),
      };

      const apiUrl = process.env.VUE_APP_API_URL;
      axios
        .post(`${apiUrl}/transaction/send`, payload, {
          headers: {
            Authorization: `Bearer ${userAuthToken}`,
          },
        })
        .then(() => {
          this.fetchTransactions();
          this.$emit('transaction-sent');
        })
        .catch((error) => {
          console.error('Error sending transaction:', error.response?.data.error);
          alert('Transaction failed: ' + error.response?.data.error);
        });
    },
    fetchUserBalance() {
      this.$refs.walletToolbar.fetchUserBalance();
    },
    fetchTransactions() {
      const userAuthToken = localStorage.getItem('userAuthenticated');
      if (!userAuthToken) {
        this.$router.push('/login');
        return;
      }

      const apiUrl = process.env.VUE_APP_API_URL;
      axios
        .get(`${apiUrl}/transactions`, {
          headers: {
            Authorization: `Bearer ${userAuthToken}`,
          },
        })
        .then((response) => {
          const currentUser = JSON.parse(atob(userAuthToken.split('.')[1])).id;

          this.transactions = response.data
            .filter(
              (transaction) =>
                transaction.sender_id === currentUser || transaction.receiver_id === currentUser
            )
            .map((transaction) => {
              return {
                id: transaction.id,
                date: new Date(transaction.created_at).toLocaleString(),
                recipientUsername: transaction.receiver_username,
                amount: transaction.amount,
                status: transaction.status,
                transactionType: transaction.sender_id === currentUser ? 'Send' : 'Receive',
              };
            });
        })
        .catch((error) => {
          console.error('Error fetching transactions:', error);
        });
    },
    cancelTransaction(transactionId) {
      const userAuthToken = localStorage.getItem('userAuthenticated');
      if (!userAuthToken) {
        this.$router.push('/login');
        return;
      }

      const apiUrl = process.env.VUE_APP_API_URL;
      axios
        .post(`${apiUrl}/transaction/cancel/${transactionId}`, null, {
          headers: {
            Authorization: `Bearer ${userAuthToken}`,
          },
        })
        .then(() => {
          this.fetchTransactions();
        })
        .catch((error) => {
          console.error('Error canceling transaction:', error.response?.data.error);
          alert('Cancel failed: ' + error.response?.data.error);
        });
    },
    getStatusColor(status) {
      switch (status) {
        case 'completed':
          return 'green';
        case 'pending':
          return 'blue';
        case 'failed':
          return 'red';
        default:
          return 'grey';
      }
    },
     startScanning() {
      this.scanning = true;
    },
    onDecode(decodedString) {
      console.log("Decoded QR Code:", decodedString);
      this.recipientUsername = decodedString;
      this.scanning = false;
    },
    onError(error) {
      console.error('QR scan error:', error);
      this.scanning = false;
    },
    cancelScanning() {
      this.scanning = false;
    },
    fetchUsername() {
      const userAuthToken = localStorage.getItem('userAuthenticated');
      if (userAuthToken) {
        const payload = JSON.parse(atob(userAuthToken.split('.')[1]));
        this.username = payload.username;
        QRCode.toDataURL(this.username)
          .then((url) => {
            this.qrCodeData = url;
          })
          .catch((err) => {
            console.error('Error generating QR code:', err);
          });
      }
    },
  },
  mounted() {
    const userAuthToken = localStorage.getItem('userAuthenticated');
    if (!userAuthToken) {
      this.$router.push('/login');
    } else {
      this.fetchTransactions();
      this.fetchUsername();
    }
  },
};
</script>

<style>
.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.transaction-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.transaction-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transaction-table tr:hover {
  background-color: #f1f1f1;
}

.transaction-table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.transaction-table {
  min-width: 800px; 
}

@media (max-width: 600px) {
  .transaction-table {
    font-size: 12px;
  }

  .transaction-table th,
  .transaction-table td {
    padding: 4px;
  }
}
</style>