<template>
  <v-container>
    <v-row>
      <!-- Dashboard Cards -->
      <v-col v-for="(card, index) in cards" :key="index" cols="12" md="4">
        <v-card>
          <v-card-title>{{ card.title }}</v-card-title>
          <v-card-subtitle>{{ card.subtitle }}</v-card-subtitle>
          <v-card-actions>
            <v-btn color="primary">View</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- You can add more sections here like charts, graphs, or tables -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Recent Activity</v-card-title>
          <v-card-text>
            <!-- Placeholder for a list or table of recent activity -->
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>New User Registered</v-list-item-title>
                  <v-list-item-subtitle>5 minutes ago</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Order #12345 Processed</v-list-item-title>
                  <v-list-item-subtitle>10 minutes ago</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- More items can be added here -->
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AdminDashboard',
  data() {
    return {
      cards: [
        { title: 'Users', subtitle: 'Total Registered Users: 350' },
        { title: 'Transactions', subtitle: 'Pending Transactions: 12' },
      ],
    };
  },
};
</script>

<style scoped>
/* You can add custom styles for your dashboard here */
</style>